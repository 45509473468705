@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap');


ul.navbar {
    margin: 3vmin auto;
    list-style: none;
    display: flex;
    justify-content: flex-end;
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0.772), rgba(0, 0, 0, 0.706));
    height: 8vmin;
    width: 100vmin;
    align-items: center;
    box-shadow: 3px 4px 10px 0 rgba(0, 0, 0, 0.852);
}
  
.navbar li {
    margin-right: 7vmin;
    font-size: 4vmin;
    font-family: 'Luckiest Guy', cursive;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.navbar a {
    text-decoration: none;
    color: black;
}

.navbar > a:hover {
    color: rgba(255, 217, 0, 0.9);
    text-shadow: 2px 2px 2px black;
}

li.navbar span {
    margin-bottom: -2vmin;
}

.navbar > img {
    height: 5vmin;
}

