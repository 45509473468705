:root {
  --main-txt-red: rgba(255, 0, 0, 0.649);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: rgba(223, 148, 19, 0.615);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

section {
  margin: 3vmin auto;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.772), rgba(0, 0, 0, 0.706));
  min-height: 80vmin;
  width: 100vmin;
  box-shadow: 3px 4px 10px 0 rgba(0, 0, 0, 0.852);
  display: flex;
  flex-direction: row;
}

.search-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.results-section {
  display: flex;
  flex-direction: column;
  padding: 5vmin 0;
}

.recipe-section {
  display: grid;
  padding: 3vmin;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: .2fr 1fr .75fr 1fr;
  gap: 1vmin;
  justify-items: center;
  align-items: center;
}

.recipe-div {
  grid-row: 1/2;
  grid-column: 1/3;
  text-align: center;
}

.recipe-instructions {
  grid-row: 3/4;
  grid-column: 1/3;
  background-color: rgba(255, 255, 255, 0.197);
  padding: 4vmin;
  box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
}

.video-div {
  grid-row: 4/5;
  grid-column: 1/3;
  background-color: rgba(255, 255, 255, 0.197);
  padding: 4vmin;
  box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.5);
  margin-bottom: 5vmin;
}

div.right-div, div.left-div{
  height: 80vmin;
  width: 50vmin;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.categories-div, .results-div {
  display: flex;
  flex-wrap: wrap; 
  margin: 0 auto;
  justify-content: center;
}

article.random-article {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 1vmin;
}

article.right-article {
  display: grid;
  grid-template-rows: 1fr 2fr 2fr 1.5fr;
  padding: 1vmin;
  background-color: rgba(255, 255, 255, 0.197);
  margin: 0 2vmin;
}

.results-h1, .recipe-h1 {
  font-family: 'Luckiest Guy';
  font-size: 4vmin;
}

h1 {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 3vmin;
  font-weight: lighter;
  text-align: center;
}

h1.right-h1 {
  grid-row: 3/4;
  margin: 2vmin 2vmin;
  text-align: center;
  font-weight: 400;
}

h2 {
  font-family: 'Luckiest Guy';
  font-size: 4.5vmin;
  font-weight: 100;
  text-align: center;
}

h2.random-h2 {
  margin: 3vmin 0 3vmin 0;
}

h3 {
  font-family: 'Luckiest Guy';
  font-weight: 100;
  font-size: 3.5vmin;
  text-align: center;
}

h3.flash {
  animation: flash infinite 3s linear;
  grid-row: 2/3;
  align-self: center;
}

@keyframes flash {
  0% {font-size: 3.5vmin;}
  25% {font-size: 4vmin;}
  50% {font-size: 4.5vmin;}
  100% {font-size: 3.5vmin;}
}

.recipe-img {
  width: 40vmin;
  height: 40vmin;
  border-radius: 40%;
  border: 3px solid black;
  padding: 15px;
  box-shadow: 0 3px 3px 3px rgba(0, 0, 0, 0.627);
  grid-row: 2/3;
  grid-column: 1/2;
}

span.nationality, span.category {

}

table {
  width: fit-content;
  grid-row: 2/3;
  grid-column: 2/3;
  background-color: rgba(255, 255, 255, 0.197);
  padding: 4vmin;
  box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.5);
}

.measure {
  text-align: right;
  padding: 0 1vmin;
}

img.random-img {
  width: 45vmin;
  margin: 0 auto;
  border-radius: 50%;
  border: 3px solid black;
  padding: 15px;
  box-shadow: 0 3px 3px 3px rgba(0, 0, 0, 0.627);
}

.categories-div, .dish-div {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  /* border: 1px solid black; */
}

div.categories-div {
  align-content: center;
}

div.hourglass-div {
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.hourglass {
  height: 15vmin;
  pointer-events: none;
  margin: 25vh auto;
}

@media (prefers-reduced-motion: no-preference) {
  .hourglass {
    animation: hourglass-spin infinite 5s linear;
  }
}

@keyframes hourglass-spin {
  from {
    transform: rotate3d(0);
  }
  to {
    transform: rotate3d(1, 0, 0, 360deg);
  }
}