.dish-div {
    width: 30vmin;
    height: 50vmin;
    margin: 2vmin 1vmin;
    display: flex;
    flex-direction: column;
}

img.dish-thumb {
    width: 27vmin;
    box-shadow: 10px 10px 10px 0 rgba(0, 0, 0, 0.646);
    margin: 0 auto;
}

img.dish-thumb:hover {
    transform: scale(1.75);
    border-radius: 50%;
    border: 1px solid black;
    padding: 5px;
    box-shadow: 0 3px 3px 3px rgba(0, 0, 0, 0.627);
}

h4.dish-name {
    font-family: 'Luckiest Guy';
    color: rgba(255, 217, 0, 0.9);
    text-shadow: 2.5px 2.5px 2.5px rgba(0, 0, 0, 0.87);
    letter-spacing: .25vmin;
    font-weight: 100;
    font-size: 2.5vmin;
    min-height: 10vmin;
    text-align: center;
    word-wrap: normal;
    background: rgba(0, 0, 0, 0.3);
    padding: 2.75vmin 1vmin;
}