.form {
    margin: 0 auto;
    height: 40vmin;
    width: 60vmin;
    background-color: rgba(255, 255, 255, 0.197);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.form-h1 {
    font-family: 'Luckiest Guy';
    font-size: 4vmin;
}

.ingredient-div {
    display: flex;
    flex-direction: column;
}

input {
    width: 40vmin;
    height: 5vmin;
    font-size: 3vmin;
    margin-top: 2vmin;
    margin-bottom: 2vmin;
    border: 1px solid black;
    padding: 1vmin
}

.ingredient-span {
    text-align: center;
}
select {
    width: 40vmin;
    height: 5vmin;
    font-size: 3vmin;
    text-align: center;
    margin-top: 2vmin;
    margin-bottom: 2vmin;
}

button {
    height: 5vmin;
    width: 20vmin;
    border-radius: 4vmin;
    background-color: black;
    color: white;
    border: none;
    font-size: 2vmin;
    font-weight: 900;
}